import { createGlobalStyle } from "styled-components";
// @import url("https://fonts.googleapis.com/css2?family=Overpass&display=swap")
export const GlobalStyle = createGlobalStyle`

* {
    margin:0;
    padding:0;
    font-family: 'Overpass';
    font-size: 20px;

}


`;
